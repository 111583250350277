import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props}xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 0C5.59548 0 0 5.46497 0 12.2085C0 18.952 5.59548 24.417 12.5 24.417C19.4045 24.417 25 18.952 25 12.2085C25 5.46763 19.4018 0 12.5 0ZM18.3404 12.7162C18.3404 13.6465 18.2234 14.5768 18.2234 14.5768C18.2234 14.5768 18.1091 15.3689 17.7553 15.7171C17.309 16.1743 16.8055 16.177 16.5769 16.2035C14.9303 16.3205 12.4565 16.3232 12.4565 16.3232C12.4565 16.3232 9.39745 16.2966 8.4558 16.2089C8.19454 16.161 7.60669 16.1743 7.15763 15.7171C6.80384 15.3689 6.68953 14.5768 6.68953 14.5768C6.68953 14.5768 6.57251 13.6465 6.57251 12.7162V11.8443C6.57251 10.914 6.68953 9.98368 6.68953 9.98368C6.68953 9.98368 6.80384 9.19158 7.15763 8.84337C7.60397 8.38618 8.10745 8.38353 8.33605 8.35695C9.98258 8.23999 12.4537 8.23999 12.4537 8.23999H12.4592C12.4592 8.23999 14.9303 8.23999 16.5769 8.35695C16.8082 8.38353 17.309 8.38618 17.7553 8.84337C18.1091 9.19158 18.2234 9.98368 18.2234 9.98368C18.2234 9.98368 18.3404 10.914 18.3404 11.8443V12.7162Z" />
      <path d="M11.2427 13.7741L14.4214 12.1633L11.2427 10.5446V13.7741Z" />
    </Svg>
  );
};

export default Icon;
